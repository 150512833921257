window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
  processInclude(require('./dataLayer'));
  processInclude(require('base/components/menu'));
  processInclude(require('base/components/cookie'));
  processInclude(require('base/components/consentTracking'));
  processInclude(require('base/components/footer'));
  processInclude(require('./components/miniCart'));
  processInclude(require('base/components/collapsibleItem'));
  processInclude(require('base/components/search'));
  processInclude(require('./components/clientSideValidation'));
  processInclude(require('base/components/countrySelector'));
  processInclude(require('./components/carouselUtils'));
  processInclude(require('./components/toolTip'));
});

require('base/thirdParty/bootstrap');
require('base/components/spinner');
